<template>
  <base-form
    v-slot="slotProps"
    :model="event"
    :rules="rules"
    :loading="loading"
    :form-submitted="handleSubmit"
    :submit-label="submitLabel"
    :mode="mode"
    model-name="Veranstaltung"
  >
    <el-form-item
      v-if="isSuperAdmin || isRegionUser"
      label="Veranstalter"
      prop="support_organization_id"
    >
      <el-select
        v-model="slotProps.model.support_organization_id"
        v-loading="loadingOrganizations"
        filterable
        placeholder="Bitte auswählen"
      >
        <el-option
          v-for="item in organizations"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Name" prop="name">
      <el-input
        v-model="slotProps.model.name"
        placeholder="z.B. 'Die Babywerkstatt'"
        :maxlength="nameCharLimit"
        show-word-limit
      ></el-input>
    </el-form-item>

    <el-form-item label="Veranstaltungskategorie" prop="event_category_id">
      <el-select
        v-model="slotProps.model.event_category_id"
        v-loading="loadingCategories"
        filterable
        placeholder="Bitte auswählen"
      >
        <el-option
          v-for="item in categories"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item
      label="Hat die Veranstaltung einen festen Ort?"
      prop="has_location"
    >
      <el-radio v-model="slotProps.model.has_location" :label="true"
        >Ja</el-radio
      >
      <el-radio v-model="slotProps.model.has_location" :label="false"
        >Nein (z.B. Online-Veranstaltung)</el-radio
      >
    </el-form-item>

    <el-form-item
      v-if="slotProps.model.has_location"
      label="Veranstaltungsort"
      prop="event_location_id"
    >
      <el-row :gutter="10">
        <el-col :span="14">
          <el-select
            v-if="isSuperAdmin || isRegionUser || locations.length > 0"
            v-model="slotProps.model.event_location_id"
            v-loading="loadingLocations"
            filterable
            placeholder="Bitte auswählen"
          >
            <el-option
              v-for="item in locations"
              :key="item.id"
              :label="item.long_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button v-else @click="newLocationFormVisible = true">
            Veranstaltungsort auswählen
          </el-button>
        </el-col>
        <el-col :span="10">
          <el-button
            v-if="!isSuperAdmin && !isRegionUser && locations.length > 0"
            icon="fal fa-plus"
            @click="newLocationFormVisible = true"
          >
            Neuer Veranstaltungsort
          </el-button>
        </el-col>
      </el-row>
    </el-form-item>

    <new-location-modal
      :show.sync="newLocationFormVisible"
      :saving="savingLocation"
      @form-submitted="createLocation"
    />

    <el-form-item label="Beschreibung" prop="description">
      <el-input
        v-model="slotProps.model.description"
        type="textarea"
        :rows="5"
        placeholder="Bitte geben Sie eine kurze und knackige Beschreibung Ihrer Veranstaltung ein."
        :maxlength="descriptionCharLimit"
        show-word-limit
      ></el-input>
    </el-form-item>

    <el-form-item label="Name Leitung" prop="instructor_name">
      <el-input v-model="slotProps.model.instructor_name"></el-input>
    </el-form-item>

    <el-form-item
      label="Berufliche Qualifikation Leitung"
      prop="instructor_qualifications"
    >
      <el-input
        v-model="slotProps.model.instructor_qualifications"
        placeholder="Ausbildung, Abschluss, o.ä."
      ></el-input>
    </el-form-item>

    <el-card class="box-card">
      <div slot="header">
        <span><b>Veranstaltungstermine</b></span>
      </div>
      <div style="margin-bottom: 8px;">
        <el-row :gutter="40">
          <el-col :span="8">
            Beginn
          </el-col>
          <el-col :span="8">
            Ende
          </el-col>
          <el-col :span="8"> </el-col>
        </el-row>
      </div>
      <div
        v-for="item in datesWithoutDeleted"
        :key="item.id"
        style="margin-bottom: 16px;"
        class="date-item"
      >
        <el-row :gutter="40">
          <el-col :span="8">
            <el-date-picker
              v-model="item.starts_at"
              class="starts-at-picker"
              :clearable="false"
              format="dd.MM.yyyy HH:mm"
              type="datetime"
              placeholder="Startzeit auswählen"
              :picker-options="pickerOptions"
              @change="setEndsAt(item)"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="8">
            <el-date-picker
              v-model="item.ends_at"
              class="ends-at-picker"
              :clearable="false"
              format="dd.MM.yyyy HH:mm"
              type="datetime"
              placeholder="Ende auswählen"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="8">
            <el-button
              class="remove-date-button"
              type="danger"
              icon="fal fa-trash-alt"
              @click="removeDate(item)"
            >
              Entfernen
            </el-button>
          </el-col>
        </el-row>
      </div>

      <el-row :gutter="40" style="margin-bottom: 24px">
        <el-col :span="8">
          <el-button
            class="btn-add-date"
            type="primary"
            icon="fal fa-plus"
            @click="addDate"
          >
            Termin hinzufügen
          </el-button>
        </el-col>
        <el-col :span="8"><span style="opacity: 0;">h</span></el-col>
        <el-col :span="8">
          <el-button
            class="btn-add-date"
            type="danger"
            icon="fal fa-trash-alt"
            @click="removeDates"
          >
            Termine leeren
          </el-button>
        </el-col>
      </el-row>

      <el-row :gutter="40" style="margin-bottom: 24px">
        <el-col :span="8">
          <el-button
            class="btn-add-date"
            icon="fal fa-plus"
            @click="addManyDates"
          >
            Viele Termine hinzufügen
          </el-button>
        </el-col>
      </el-row>

      <el-form-item
        v-if="displayLaterEntryOption"
        label="Quereinstieg nach Start möglich?"
      >
        <el-switch
          v-model="slotProps.model.later_entry"
          active-text="Ja"
          inactive-text="Nein"
        ></el-switch>
      </el-form-item>
    </el-card>

    <el-form-item label="Anmeldung erforderlich?">
      <el-switch
        v-model="slotProps.model.notifiable"
        active-text="Ja"
        inactive-text="Nein"
      ></el-switch>
    </el-form-item>

    <el-collapse-transition>
      <div v-if="slotProps.model.notifiable">
        <el-form-item
          label="Kontaktperson Anmeldung"
          prop="registration_contact_person_name"
        >
          <el-input
            v-model="slotProps.model.registration_contact_person_name"
          ></el-input>
        </el-form-item>

        <el-form-item label="Telefonnummer Anmeldung" prop="registration_phone">
          <el-input v-model="slotProps.model.registration_phone"></el-input>
        </el-form-item>

        <el-form-item label="E-Mail Anmeldung" prop="registration_email">
          <el-input
            v-model.trim="slotProps.model.registration_email"
          ></el-input>
        </el-form-item>

        <el-form-item label="Website URL Anmeldung" prop="registration_url">
          <el-input
            v-model="slotProps.model.registration_url"
            placeholder="https://www.beispiel.de"
          ></el-input>
        </el-form-item>
      </div>
    </el-collapse-transition>

    <el-form-item label="Veranstaltung kostenpflichtig?">
      <el-switch
        v-model="slotProps.model.chargeable"
        active-text="Ja"
        inactive-text="Nein"
      ></el-switch>
    </el-form-item>

    <el-collapse-transition>
      <div v-if="slotProps.model.chargeable">
        <el-form-item label="Kosten bitte in € angeben" prop="costs">
          <el-input
            v-model="slotProps.model.costs"
            type="textarea"
            :rows="2"
            placeholder="z.B. '10€ pro Veranstaltung'"
          ></el-input>
        </el-form-item>
      </div>
    </el-collapse-transition>

    <el-form-item label="Veranstaltung rollstuhlgerecht?">
      <el-switch
        v-model="slotProps.model.accessibility_wheelchair"
        active-text="Ja"
        inactive-text="Nein"
      ></el-switch>
    </el-form-item>

    <el-form-item label="Veranstaltung blindenfreundlich?">
      <el-switch
        v-model="slotProps.model.accessibility_blind"
        active-text="Ja"
        inactive-text="Nein"
      ></el-switch>
    </el-form-item>

    <el-form-item label="Veranstaltung mit Gebärdensprache?">
      <el-switch
        v-model="slotProps.model.accessibility_deaf"
        active-text="Ja"
        inactive-text="Nein"
      ></el-switch>
    </el-form-item>

    <el-card class="box-card">
      <div slot="header">
        <span><b>Kontaktinformationen</b></span>
      </div>
      <div v-if="!slotProps.model.custom_contact" style="margin-bottom: 16px;">
        <div style="margin-bottom: 8px;">
          <b>Kontaktperson für Rückfragen der Teilnehmer:</b><br />
          {{ defaultContactInfo.contact_person_name }}
        </div>

        <div style="margin-bottom: 8px;">
          <b>Telefonnummer für Rückfragen der Teilnehmer:</b><br />
          {{ defaultContactInfo.phone }}
        </div>

        <div style="margin-bottom: 8px;">
          <b>E-Mail für Rückfragen der Teilnehmer:</b><br />
          {{ defaultContactInfo.email }}
        </div>
      </div>
      <el-form-item label="Abweichende Kontaktinformationen?">
        <el-switch
          v-model="slotProps.model.custom_contact"
          active-text="Ja"
          inactive-text="Nein"
        ></el-switch>
      </el-form-item>
      <el-collapse-transition>
        <div v-if="slotProps.model.custom_contact">
          <el-form-item
            label="Kontaktperson für Rückfragen der Teilnehmer"
            prop="custom_contact_person_name"
          >
            <el-input
              v-model="slotProps.model.custom_contact_person_name"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="Telefonnummer für Rückfragen der Teilnehmer"
            prop="custom_contact_phone"
          >
            <el-input v-model="slotProps.model.custom_contact_phone"></el-input>
          </el-form-item>

          <el-form-item
            label="E-Mail für Rückfragen der Teilnehmer"
            prop="custom_contact_email"
          >
            <el-input
              v-model.trim="slotProps.model.custom_contact_email"
            ></el-input>
          </el-form-item>
        </div>
      </el-collapse-transition>
    </el-card>

    <el-form-item label="Spezielle Hinweise zur Veranstaltung" prop="notes">
      <el-input
        v-model="slotProps.model.notes"
        type="textarea"
        :rows="2"
        placeholder="z.B. Kursnummer, Raumnummer, Barrierefreiheit, etc."
        :maxlength="notesCharLimit"
        show-word-limit
      ></el-input>
    </el-form-item>

    <el-form-item label="Zusätzliche Website URL" prop="website_url">
      <el-input
        v-model="slotProps.model.website_url"
        placeholder="Website mit zusätzlichen Hinweisen und Informationen zur Veranstaltung"
      ></el-input>
    </el-form-item>

    <el-form-item v-if="getRegionId === 20" label="Übernahme">
      <el-checkbox v-model="syncToTarget">
        In Klima-App übernehmen
      </el-checkbox>
    </el-form-item>

    <el-dialog
      title="Viele Termine hinzufügen"
      :visible.sync="showManyDatesDialog"
      width="50%"
    >
      <div style="max-width: 500px;">
        <el-row :gutter="4" style="margin-bottom: 16px">
          <el-col :span="12">
            <div>Beginn</div>
            <el-date-picker
              v-model="ruleStartDate"
              style="margin-top: 4px; max-width: 200px;"
              :clearable="false"
              format="dd.MM.yyyy"
              placeholder="Start auswählen"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-col>
          <el-col :span="12">
            <div>Uhrzeit</div>
            <el-time-picker
              v-model="ruleTimeRange"
              style="margin-top: 4px; max-width: 200px;"
              is-range
              format="HH:mm"
              start-placeholder="Startzeit"
              end-placeholder="Endzeit"
            >
            </el-time-picker>
          </el-col>
        </el-row>

        <el-row :gutter="4" style="margin-bottom: 16px">
          <el-col :span="12">
            <div>Anzahl Termine</div>
            <el-input-number
              v-model="ruleCount"
              style="margin-top: 4px; width: 200px;"
              :min="2"
              :max="60"
            ></el-input-number>
          </el-col>
          <el-col :span="12">
            <div>Regel</div>
            <el-select
              v-model="ruleSelectedOption"
              placeholder="Auswählen"
              style="margin-top: 4px; max-width: 200px;"
            >
              <el-option
                v-for="item in ruleSelectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>

      <div class="date-examples">
        <strong>Termine:</strong>
        <ul>
          <li v-for="(date, index) in datesRuleDates" :key="index">
            {{ format(date, "dd.LL.yyyy") }}
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showManyDatesDialog = false">Abbrechen</el-button>
        <el-button type="primary" @click="addDatesFromRule"
          >Hinzufügen</el-button
        >
      </span>
    </el-dialog>
  </base-form>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { RRule } from "rrule"
import {
  format,
  parseISO,
  addDays,
  addHours,
  isSameDay,
  getHours,
  getMinutes,
  setHours,
  setMinutes
} from "date-fns"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"
import BaseForm from "@/components/forms/BaseForm"
import EventLocationsRepository from "@/repositories/event_locations_repository.js"
import EventCategoriesRepository from "@/repositories/event_categories_repository.js"
import SupportOrganizationsRepository from "@/repositories/support_organizations_repository.js"
import SupportOrganizationRepository from "@/repositories/organization/support_organizations_repository.js"
import NewLocationModal from "@/components/NewLocationModal"

export default {
  components: {
    NewLocationModal,
    BaseForm
  },
  mixins: [ApiErrorHandlerMixin],
  props: {
    event: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      format,
      showManyDatesDialog: false,
      nameCharLimit: 60,
      descriptionCharLimit: 500,
      notesCharLimit: 140,
      loadingLocations: true,
      locations: [],
      loadingCategories: true,
      categories: [],
      loadingOrganizations: true,
      organizations: [],
      newLocationFormVisible: false,
      savingLocation: false,
      organization: {},
      pickerOptions: {
        firstDayOfWeek: 1
      },
      syncToTarget: false,
      ruleStartDate: new Date(),
      ruleTimeRange: [
        new Date(2016, 9, 10, 8, 0),
        new Date(2016, 9, 10, 10, 0)
      ],
      ruleCount: 5,
      ruleSelectedOption: "DAILY",
      ruleSelectOptions: [
        {
          value: "DAILY",
          label: "Täglich"
        },
        {
          value: "WEEKLY",
          label: "Wöchentlich"
        },
        {
          value: "BIWEEKLY",
          label: "Alle zwei Wochen"
        },
        {
          value: "MONTHLY",
          label: "Monatlich"
        }
      ]
    }
  },
  computed: {
    displayLaterEntryOption() {
      if (this.datesWithoutDeleted.length > 1) {
        return true
      }

      if (this.datesWithoutDeleted.length > 0) {
        let starts_at =
          this.datesWithoutDeleted[0].starts_at instanceof Date
            ? this.datesWithoutDeleted[0].starts_at
            : parseISO(this.datesWithoutDeleted[0].starts_at)
        let ends_at =
          this.datesWithoutDeleted[0].ends_at instanceof Date
            ? this.datesWithoutDeleted[0].ends_at
            : parseISO(this.datesWithoutDeleted[0].ends_at)

        if (!isSameDay(starts_at, ends_at)) {
          return true
        }
      }

      return false
    },
    datesRule() {
      return new RRule({
        freq:
          this.ruleSelectedOption == "BIWEEKLY"
            ? RRule.WEEKLY
            : RRule[this.ruleSelectedOption],
        dtstart: new Date(
          Date.UTC(
            this.ruleStartDate.getFullYear(),
            this.ruleStartDate.getMonth(),
            this.ruleStartDate.getDate(),
            this.ruleStartDate.getHours(),
            this.ruleStartDate.getMinutes(),
            0
          )
        ),
        count: this.ruleCount,
        interval: this.ruleSelectedOption == "BIWEEKLY" ? 2 : 1
        // tzid: "Europe/Berlin"
        // until: new Date(Date.UTC(2022, 12, 31))
      })
    },
    datesRuleDates() {
      return this.datesRule.all()
    },
    rules() {
      return {
        support_organization_id: [
          {
            required: this.isSuperAdmin || this.isRegionUser,
            message: "Bitte Veranstalter auswählen",
            trigger: "change"
          }
        ],
        name: [
          { required: true, message: "Bitte Namen angeben", trigger: "blur" }
        ],
        event_location_id: [
          {
            required: this.event.has_location === true,
            message: "Bitte Ort auswählen",
            trigger: "change"
          }
        ],
        event_category_id: [
          {
            required: true,
            message: "Bitte Kategorie auswählen",
            trigger: "change"
          }
        ],
        description: [
          {
            required: true,
            message: "Bitte Beschreibung angeben",
            trigger: "blur"
          }
        ],
        registration_contact_person_name: [
          {
            required: this.event.notifiable === true,
            message: "Bitte Namen angeben",
            trigger: "blur"
          }
        ],
        registration_phone: [
          {
            required: this.event.notifiable === true,
            message: "Bitte Telefonnummer angeben",
            trigger: "blur"
          }
        ],
        registration_email: [
          {
            type: "email",
            required: false,
            message: "Bitte gültige E-Mail-Adresse angeben",
            trigger: "blur"
          }
        ],
        registration_url: [
          {
            type: "url",
            message: "Bitte gültige URL mit https:// oder http:// angeben",
            trigger: "blur"
          }
        ],
        costs: [
          {
            required: this.event.chargeable === true,
            message: "Bitte Kosteninformationen angeben",
            trigger: "blur"
          }
        ],
        custom_contact_person_name: [
          {
            required: this.event.custom_contact === true,
            message: "Bitte Namen angeben",
            trigger: "blur"
          }
        ],
        custom_contact_phone: [
          {
            required: this.event.custom_contact === true,
            message: "Bitte Telefonnummer angeben",
            trigger: "blur"
          }
        ],
        custom_contact_email: [
          {
            type: "email",
            required: this.event.custom_contact === true,
            message: "Bitte gültige E-Mail-Adresse angeben",
            trigger: "blur"
          }
        ],
        website_url: [
          {
            type: "url",
            message: "Bitte gültige URL mit https:// oder http:// angeben",
            trigger: "blur"
          }
        ]
      }
    },
    datesWithoutDeleted() {
      return this.event.dates_attributes.filter(date => date._destroy !== true)
    },
    defaultContactInfo() {
      if (this.isSuperAdmin || this.isRegionUser) {
        return this.getSelectedOrganization
      } else {
        return this.organization
      }
    },
    getSelectedOrganization() {
      if (this.event.support_organization_id && this.organizations.length > 0) {
        return this.organizations.find(
          org => org.id === this.event.support_organization_id
        )
      } else {
        return {
          contact_person_name: "Kein Veranstalter ausgewählt",
          email: "Kein Veranstalter ausgewählt",
          phone: "Kein Veranstalter ausgewählt"
        }
      }
    },
    ...mapGetters("auth", [
      "isSuperAdmin",
      "isOrganizationUser",
      "isRegionUser",
      "getRegionId"
    ])
  },
  watch: {
    syncToTarget: {
      handler(newVal) {
        if (newVal === true) {
          this.event.target_event_category_id = 447
        } else {
          this.event.target_event_category_id = null
        }
      }
    },
    "event.target_event_category_id": {
      handler(newVal) {
        // Update syncToTarget checkbox when target_event_category_id changes
        this.syncToTarget = !!newVal
      },
      immediate: true
    }
  },
  async created() {
    EventLocationsRepository.getAll({ show_all: true })
      .then(data => {
        this.locations = data.event_locations
        this.loadingLocations = false
      })
      .catch(error => {
        this.loadingLocations = false
        this.handleApiError(error, "Fehler Laden Veranstaltungsorte")
      })

    EventCategoriesRepository.getAll()
      .then(categories => {
        this.categories = categories
        this.loadingCategories = false
      })
      .catch(error => {
        this.loadingCategories = false
        this.handleApiError(error, "Fehler Laden Veranstaltungskategorien")
      })

    if (this.isSuperAdmin || this.isRegionUser) {
      SupportOrganizationsRepository.getAll({ show_all: true })
        .then(data => {
          this.organizations = data.support_organizations
          this.loadingOrganizations = false
        })
        .catch(error => {
          this.loadingOrganizations = false
          this.handleApiError(error, "Fehler Laden Veranstalter")
        })
    } else {
      try {
        this.organization = await SupportOrganizationRepository.get()
      } catch (error) {
        this.handleApiError(error)
      }
    }
  },
  methods: {
    addDate() {
      let last_date = this.datesWithoutDeleted[
        this.datesWithoutDeleted.length - 1
      ]
      this.event.dates_attributes.push({
        starts_at: this.addOneDay(last_date.starts_at),
        ends_at: this.addOneDay(last_date.ends_at)
      })
    },
    addOneDay(date) {
      if (typeof date === "string") {
        return addDays(parseISO(date), 1)
      } else {
        return addDays(date, 1)
      }
    },
    addTwoHours(date) {
      if (typeof date === "string") {
        return addHours(parseISO(date), 2)
      } else {
        return addHours(date, 2)
      }
    },
    removeDate(date) {
      if (this.datesWithoutDeleted.length > 1) {
        Vue.set(date, "_destroy", true)
      } else {
        this.$message({
          message: "Veranstaltung muss mindestens einen Termin haben.",
          type: "error",
          showClose: true
          //duration: 0
        })
      }
    },
    removeDates() {
      this.$confirm(
        "Möchten Sie alle Termine dieser Veranstaltung entfernen?",
        "Termine entfernen",
        {
          confirmButtonClass: "el-button--danger",
          confirmButtonText: "Entfernen",
          cancelButtonText: "Abbrechen",
          type: "warning"
        }
      )
        .then(async () => {
          if (this.datesWithoutDeleted.length > 1) {
            this.datesWithoutDeleted.forEach(function(date, index) {
              if (index != 0) {
                Vue.set(date, "_destroy", true)
              }
            })
          }
        })
        .catch(() => {})
    },
    addManyDates() {
      this.showManyDatesDialog = true
    },
    addDatesFromRule() {
      const that = this
      this.datesRuleDates.forEach(function(date) {
        let start = setHours(date, getHours(that.ruleTimeRange[0]))
        start = setMinutes(start, getMinutes(that.ruleTimeRange[0]))

        let end = setHours(date, getHours(that.ruleTimeRange[1]))
        end = setMinutes(end, getMinutes(that.ruleTimeRange[1]))

        that.event.dates_attributes.push({
          starts_at: start,
          ends_at: end
        })
      })

      this.showManyDatesDialog = false
    },
    async createLocation(location) {
      this.savingLocation = true

      try {
        let response = await EventLocationsRepository.create(location)
        let loc = response.event_location
        this.savingLocation = false
        this.newLocationFormVisible = false
        this.locations.push(loc)
        this.event.event_location_id = loc.id
        this.location = {}
      } catch (error) {
        this.savingLocation = false
        this.handleApiError(error)
      }
    },
    setEndsAt(item) {
      let startsAt
      if (typeof item["starts_at"] === "string") {
        startsAt = parseISO(item["starts_at"])
      } else {
        startsAt = item["starts_at"]
      }

      if (startsAt) {
        let endsAt
        if (typeof item["ends_at"] === "string") {
          endsAt = parseISO(item["ends_at"])
        } else {
          endsAt = item["ends_at"]
        }
        let newEnd = new Date(startsAt.getTime())
        newEnd = setHours(newEnd, getHours(endsAt))
        newEnd = setMinutes(newEnd, getMinutes(endsAt))
        Vue.set(item, "ends_at", newEnd)
      }
    },
    handleSubmit() {
      const laterEntryNoteUnderstood = localStorage.getItem(
        "familien-app-later-entry-note-understood"
      )

      if (
        !laterEntryNoteUnderstood &&
        this.event.later_entry == false &&
        this.displayLaterEntryOption == true
      ) {
        this.$alert(
          "Wenn Sie <i>»Quereinstieg«</i> auf <i>»Nein«</i> setzen, werden alle Folgetermine einer Veranstaltung <b>automatisch aus dem Kalender ausgeblendet</b>, sobald der erste Termin stattgefunden hat." +
            "<br><br>Eine Erklärung dazu finden Sie <a href='https://studionomai.notion.site/Hilfe-zum-Quereinstieg-158b6e6b4b3a80e68638cf88e1df9f7c' target='_blank'>hier</a>.",
          "Wichtiger Hinweis zum Quereinstieg",
          {
            type: "warning",
            showClose: false,
            dangerouslyUseHTMLString: true,
            confirmButtonText: "Verstanden",
            callback: () => {
              localStorage.setItem(
                "familien-app-later-entry-note-understood",
                true
              )
            }
          }
        )
        throw new Error("Save cancelled")
      } else {
        this.formSubmitted(this.event)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.date-examples {
  max-height: 150px;
  overflow-y: scroll;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 16px;

  ul {
    padding: 0 0 0 16px;
    margin: 0;
    margin-top: 4px;
  }
}
</style>
